/* eslint-disable import/prefer-default-export */

import apiRequestActionCreator from './apiRequestActionCreator';

export function fetchVersion() {
    return apiRequestActionCreator({
        actionName: 'VERSION',
        params: {
            baseURL: '',
            url: `/version.json?v=1723114221`,
        },
    });
}
